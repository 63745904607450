import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, addDoc, onSnapshot, doc, setDoc, deleteDoc, query, where} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { v4 as uuidv4 } from 'uuid';

const Dashboard = () => {
  const [forms, setForms] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userFormsQuery = query(collection(db, 'forms'), where('userId', '==', currentUser.uid));
        const unsubForms = onSnapshot(userFormsQuery, (snapshot) => {
          const formsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setForms(formsList);
          console.log('reees')
          setLoading(false);
        });

        return () => unsubForms();
      } else {
        setUser(null);
        setLoading(false);
        console.log('errrrr')
      }
    });

    return () => unsubAuth();
  }, []);


  const handleNewForm = async () => {
    if (user) {
      const uniqueKey = uuidv4();
      const docRef = await addDoc(collection(db, 'forms'),  {
        playerA: 'Player A',
        playerB: 'Player B',
        breakPointLabel: 'Break Point',
        playerAImage: '/assets/images/fr.png',
        playerBImage: '/assets/images/en.png',
        imagesOn: true ,
        numberOfSets: 3,
        currentSet: 1,
        playerAPoints:0,
        playerBPoints:0,
        currentGamePointsA:0,
        currentGamePointsB:0,
        gamesPointsA: Array(3).fill(0),
        gamesPointsB: Array(3).fill(0),
        serve:'0',
        switchBreak: false ,
        tieBreak: false,
        tieBreakA:0,
        tieBreakB:0,
        matchTime: false,
        hours: 0,
        minutes:0,
        seconds:0,
        showSponsors:true,
        sponsors: [],
        sponsorWidth:65,
        sponsorsBottom:85,
        sponsorsLeft:0,
        showSponsorsW:true,
        sponsorsW: [],
        sponsorWidthW:100,
        sponsorsBottomW:60,
        sponsorsLeftW:0,
        uniqueKey: uniqueKey,
        userId: user.uid
      });
      navigate(`/edit-form/${docRef.id}`, { target: "_blank" });
    }
  };

  const handleFormClick = (formId) => {
    window.open(`/edit-form/${formId}`, '_blank');
  };

  const handleDeleteForm = async (formId) => {
    await deleteDoc(doc(db, 'forms', formId));
  };

  return (
    <div className='dashboardPPage edit-form-container'>
    <div className="c-container mt-4">
      <button onClick={handleNewForm} className="createButton c-btn c-btn-primary mb-3">Create New Match</button>
      <div className="c-card c-card-body preview">
        <h2 className="c-card-title">Your Matches</h2>
        <ul className="list-group">
          {loading ? (
            <CSpinner style={{display:"block",margin:"auto",marginTop:"20px" , marginBottom:"30px" , width: '3rem', height: '3rem'}} color="default" size="sm"  />
          ) : (
            forms.map(form => (
              <li key={form.id} className="list-group-item d-flex justify-content-between align-items-center">
                <span>{form.playerA} vs {form.playerB}</span>
                <span>
                <button onClick={() => handleFormClick(form.id)} className="c-btn c-btn-outline-primary">
                <CIcon size={'5xl'} name={'cilPencil'} /> Edit</button> &nbsp;
                <button onClick={() => handleDeleteForm(form.id)} className="c-btn c-btn-outline-primary deleteButton">
                <CIcon size={'5xl'} name={'cilTrash'} /> Delete</button>
                </span>
                </li>
            ))
          )}
        </ul>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
