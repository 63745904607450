import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBQRo4feKWomPhuke1YGgu-K72TA1SpW1w",
    authDomain: "tennis-47f0b.firebaseapp.com",
    projectId: "tennis-47f0b",
    storageBucket: "tennis-47f0b.appspot.com",
    messagingSenderId: "758006673481",
    appId: "1:758006673481:web:0bd2d8e58bee4d90854f98",
    measurementId: "G-SQ01NXDP06"
  };


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };

