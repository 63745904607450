import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
//import { doc, getDoc } from 'firebase/firestore';
//import { collection, query, where, getDocs } from 'firebase/firestore';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const Display = () => {
  const { uniqueKey } = useParams();
  const [formData, setFormData] = useState(null);
  const [errorImgA, setErrorImgA] = useState(false);
  const [errorImgB, setErrorImgB] = useState(false);
  
  const handleErrorImgA = () => {
    setErrorImgA(true)
  };
  const handleErrorImgB = () => {
    setErrorImgB(true)
  };

  // useEffect(() => {
  //   const fetchFormData = async () => {
  //     const docRef = doc(db, 'forms', id);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       setFormData(docSnap.data());
  //     } else {
  //       console.log("No such document!");
  //     }
  //   };

  //   fetchFormData();
  // }, [id]);

  // useEffect(() => {
  //   const fetchFormData = async () => {
  //     if (!uniqueKey) return; // Exit early if uniqueKey is undefined
  //     const q = query(collection(db, 'forms'), where('uniqueKey', '==', uniqueKey));
  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {
  //       setFormData(doc.data());
  //     });
  //   };
  
  //   fetchFormData();
  // }, [uniqueKey]);

  // useEffect(() => {
  //   const fetchFormData = async (uniqueKey) => { // Pass uniqueKey as argument
  //     const query = query(collection(db, 'forms'), where('uniqueKey', '==', uniqueKey)); // Build query with where clause
  //     const querySnapshot = await getDocs(query);
  
  //     if (!querySnapshot.empty) {
  //       const formData = querySnapshot.docs[0].data(); // Get data from first document (assuming unique key)
  //       setFormData(formData);
  //     } else {
  //       console.log("No form found with uniqueKey:", uniqueKey);
  //     }
  //   };
  
  //   // Call fetchFormData with the uniqueKey
  //   fetchFormData(uniqueKey); // Replace "uniqueKey" with your actual variable
  
  //   // Dependency array (optional, based on your use case)
  // }, [uniqueKey]); // Re-run useEffect if uniqueKey changes
  
  // useEffect(() => {
  //   if (!uniqueKey) return; // Exit early if uniqueKey is undefined
  
  //   const fetchFormData = async () => {
  //     try {
  //       const q = query(collection(db, 'forms'), where('uniqueKey', '==', uniqueKey));
  //       const querySnapshot = await getDocs(q);
  
  //       if (querySnapshot.empty) {
  //         console.log("Form not found");
  //         setFormData(null); // Reset formData to null
  //       } else {
  //         querySnapshot.forEach((doc) => {
  //           setFormData(doc.data());
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching form data:", error);
  //     }
  //   };
  
  //   fetchFormData();
  // }, [uniqueKey]);
  
  useEffect(() => {
    if (!uniqueKey) return; // Exit early if uniqueKey is undefined

    console.log("Fetching form data for uniqueKey:", uniqueKey);

    const q = query(collection(db, 'forms'), where('uniqueKey', '==', uniqueKey));
    const unsub = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log("Form not found");
        setFormData(null); // Reset formData to null
      } else {
        querySnapshot.forEach((doc) => {
          console.log("Form data retrieved:", doc.data());
          setFormData(doc.data());
        });
      }
    }, (error) => {
      console.error("Error fetching form data:", error);
    });

    return () => unsub(); // Clean up the subscription on unmount
  }, [uniqueKey]);


  if (!formData) return <div>Loading...</div>;

  return (
    <div className="scoreboardContainer">

   <div className="scoreboard">
      {formData.imagesOn && <div className="images">
      <div className='imgContainer imgContainer1'>
       {!errorImgA && <img  src={formData.playerAImage} alt="Player A"  onError={handleErrorImgA}  /> }
       </div> 
       <div className='imgContainer'>
       {!errorImgB && <img   src={formData.playerBImage} alt="Player B"  onError={handleErrorImgB}  /> }
       </div>
      </div>}
      <div className="details">
        <div className="player-names">
          <div className="player player1"> <span>{formData.playerA} </span> <span className='servePlayer' >
          {formData.serve=='A' && <img src='/assets/images/left-arrow.png' />}
          </span> </div>
          <div className="player player2"> <span>{formData.playerB} </span> <span className='servePlayer' >
          {formData.serve=='B'  && <img src='/assets/images/left-arrow.png' /> }
          </span>
          </div>
          {formData.switchBreak ? <div className="resultBreak">{formData.breakPointLabel}</div>
           :formData.timerOn ? <div className="resultBreak"><span>Match Time:</span> {formData.hours}:{String(formData.minutes).padStart(2, '0')}</div>
           :''}
          
        </div>
        <div className="scores">
          <div className="score-row score-row1">
            <div className={`score ${formData.tieBreak ? 'tieBreakScore' : ''} `}>{formData.tieBreak ? formData.tieBreakA : formData.playerAPoints}</div>
            <div className="sets">{formData.gamesPointsA[0]}</div>
            {formData.currentSet > 1  && <div className="sets">{formData.gamesPointsA[1]}</div>}
            {formData.currentSet > 2  && <div className="sets">{formData.gamesPointsA[2]}</div>}
            {formData.currentSet > 3  && <div className="sets">{formData.gamesPointsA[3]||0}</div>}
            {formData.currentSet > 4  && <div className="sets">{formData.gamesPointsA[4]||0}</div>}
          </div>
          <div className="score-row score-row2">
            <div className={`score ${formData.tieBreak ? 'tieBreakScore' : ''} `}>{formData.tieBreak ? formData.tieBreakB : formData.playerBPoints}</div>
            <div className="sets">{formData.gamesPointsB[0]}</div>
            {formData.currentSet > 1  && <div className="sets">{formData.gamesPointsB[1]}</div>}
            {formData.currentSet > 2  && <div className="sets">{formData.gamesPointsB[2]}</div>}
            {formData.currentSet > 3  && <div className="sets">{formData.gamesPointsB[3]||0 }</div>}
            {formData.currentSet > 4  && <div className="sets">{formData.gamesPointsB[4]||0}</div>}
            </div>
        </div>
      </div>
    </div>

    <div className="scoreboardFooter" style={{bottom:formData.sponsorsBottom , left:formData.sponsorsLeft}}>
    {formData.showSponsors && formData.sponsors && formData.sponsors.map((el, index) => (
   <span  key={index}>
  <img src={el.image} alt={el.name} style={{width:formData.sponsorWidth}} />
   </span>
    ))}
     </div>

</div>
  );
};

export default Display;
