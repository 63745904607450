import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import Cookies from 'js-cookie';
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormControl,
    CInputGroup,
    CInputGroupText,
    CRow,CSpinner
  } from '@coreui/react'
  import CIcon from '@coreui/icons-react'

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleLogin = async () => {
    console.log("logging");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      Cookies.set('user', user.uid, { expires: 7 });
      onLogin();
    } catch (error) {
      console.error("Error logging in", error);
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center loginPage">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  < >
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon name="cil-user" />
                      </CInputGroupText>
                      <CFormControl
                       placeholder="E-mail" autoComplete="email" 
                       name="email" 
                       value={email} 
                        onChange={(e) => setEmail(e.target.value)}  />
                    </CInputGroup>         

                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                      <CFormControl
                        autoComplete="current-password"
                        name="password"
                        type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"
                      />
                    </CInputGroup>
                    { error && (
                        <CAlert color="danger" >
                            Login or password incorrect
                        </CAlert>
                    )}
                    <CRow>
                      <CCol xs="6">
                        {/* <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                          type="submit" value="Connexion" width="80%" /> */}

                          <button onClick={handleLogin}>Login</button>

                      </CCol>

                    </CRow>
                  </>
                </CCardBody>
              </CCard>

            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
