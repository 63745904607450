import React, { useState } from 'react';

const Preview = ({ formData }) => {

  const [errorImgA, setErrorImgA] = useState(false);
  const [errorImgB, setErrorImgB] = useState(false);
  
  const handleErrorImgA = () => {
    setErrorImgA(true)
  };
  const handleErrorImgB = () => {
    setErrorImgB(true)
  };

  return (
    <div className="preview">
      <h2>Preview</h2>
      <div className='peviewContent'>
      <div className="scoreboardContainer">

   <div className="scoreboard">
      {formData.imagesOn && <div className="images">
      <div className='imgContainer imgContainer1'>
       {!errorImgA && <img  src={formData.playerAImage} alt="Player A"  onError={handleErrorImgA}  /> }
       </div> 
       <div className='imgContainer'>
       {!errorImgB && <img   src={formData.playerBImage} alt="Player B"  onError={handleErrorImgB}  /> }
       </div>
      </div>}
      <div className="details">
        <div className="player-names">
          <div className="player player1"> <span>{formData.playerA} </span> <span className='servePlayer' >
          {formData.serve=='A' && <img src='/assets/images/left-arrow.png' />}
          </span> </div>
          <div className="player player2"> <span>{formData.playerB} </span> <span className='servePlayer' >
          {formData.serve=='B'  && <img src='/assets/images/left-arrow.png' /> }
          </span>
          </div>
          {formData.switchBreak ? <div className="resultBreak">{formData.breakPointLabel}</div>
           :formData.timerOn ? <div className="resultBreak"><span>Match Time:</span> {formData.hours}:{String(formData.minutes).padStart(2, '0')}</div>
           :''}
          
        </div>
        <div className="scores">
          <div className="score-row score-row1">
            <div className={`score ${formData.tieBreak ? 'tieBreakScore' : ''} `}>{formData.tieBreak ? formData.tieBreakA : formData.playerAPoints}</div>
            <div className="sets">{formData.gamesPointsA[0]}</div>
            {formData.currentSet > 1  && <div className="sets">{formData.gamesPointsA[1]}</div>}
            {formData.currentSet > 2  && <div className="sets">{formData.gamesPointsA[2]}</div>}
            {formData.currentSet > 3  && <div className="sets">{formData.gamesPointsA[3]||0}</div>}
            {formData.currentSet > 4  && <div className="sets">{formData.gamesPointsA[4]||0}</div>}
          </div>
          <div className="score-row score-row2">
            <div className={`score ${formData.tieBreak ? 'tieBreakScore' : ''} `}>{formData.tieBreak ? formData.tieBreakB : formData.playerBPoints}</div>
            <div className="sets">{formData.gamesPointsB[0]}</div>
            {formData.currentSet > 1  && <div className="sets">{formData.gamesPointsB[1]}</div>}
            {formData.currentSet > 2  && <div className="sets">{formData.gamesPointsB[2]}</div>}
            {formData.currentSet > 3  && <div className="sets">{formData.gamesPointsB[3]||0 }</div>}
            {formData.currentSet > 4  && <div className="sets">{formData.gamesPointsB[4]||0}</div>}
            </div>
        </div>
      </div>
    </div>
    <div className="scoreboardFooter" style={{bottom:formData.sponsorsBottom , left:formData.sponsorsLeft}}>
    {formData.showSponsors && formData.sponsors && formData.sponsors.map((el, index) => (
   <span  key={index}>
  <img src={el.image} alt={el.name} style={{width:formData.sponsorWidth}} />
   </span>
    ))}
     </div>

</div>
     </div>
 </div>
  );
};

export default Preview;
