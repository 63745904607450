import React, { useState, useEffect , useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth , storage } from '../firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Preview from './Preview';
import QRCode from 'qrcode';
import { CSpinner } from '@coreui/react';
import { CCol,CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useNavigate , Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const EditForm = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { formId } = useParams();
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const maxFileSize = 200 * 1024;
  const [formData, setFormData] = useState({
    playerA: 'ted',
    playerB: 'raf',
    breakPointLabel: 'Break Point',
    playerAImage: '/assets/images/fr.png',
    playerBImage: '/assets/images/en.png',
    imagesOn: true ,
    numberOfSets: 3,
    currentSet: 1,
    playerAPoints:0,
    playerBPoints:0,
    gamesPointsA: Array(3).fill(0),
    gamesPointsB: Array(3).fill(0),
    serve:'0',
    switchBreak: false ,
    tieBreak: false,
    tieBreakA:0,
    tieBreakB:0,
    timerOn: false,
    hours: 0,
    minutes:0,
    seconds:0,
    showSponsors:true,
    sponsors: [],
    sponsorWidth:65,
    sponsorsBottom:85,
    sponsorsLeft:0,
    showSponsorsW:true,
    sponsorsW: [],
    sponsorWidthW:100,
    sponsorsBottomW:60,
    sponsorsLeftW:0,
    uniqueKey: 0,
    userId:null
  });
 
 // const [formData, setFormData] = useState(null)
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('Setup');
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(false);

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [qrCodeUrlW, setQrCodeUrlW] = useState('');
  const [seconds , setSeconds] =  useState(0);

  useEffect(() => {
   if(formData && formData.uniqueKey ) {
     // Generate QR code for the given text (link)
    QRCode.toDataURL(`http://sportlive.tn/display/${formData.uniqueKey}`)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((error) => {
        console.error('Failed to generate QR code:', error);
      }); 

      QRCode.toDataURL(`http://sportlive.tn/display/wide/${formData.uniqueKey}`)
      .then((url) => {
        setQrCodeUrlW(url);
      })
      .catch((error) => {
        console.error('Failed to generate QR code W:', error);
      }); 
    }
  }, [formId, loading]);

  useEffect(() => {
    const unsubAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        if (formId) {
          const formDocRef = doc(db, 'forms', formId);
          const unsubForm = onSnapshot(formDocRef, (doc) => {
            if (doc.exists()) {
              setFormData(doc.data());
              setLoading(false)
            }
          });

          return () => unsubForm();
        }
      } else {
        setUser(null);
        Cookies.remove('user');
        //navigate('/');
        window.location.assign('/'); 
      }
    });

    return () => unsubAuth();
  }, [formId]);


  const handleLogout = async () => {
    await signOut(auth);
    setIsLoggedIn(false);
    Cookies.remove('user');
    //navigate('/');
    window.location.assign('/'); 
  };

  const checkUserStatus = async (currentUser) => {
    if (currentUser) {
      try {
        // Fetch the latest user token to ensure the user is still authenticated
        await currentUser.getIdToken(true);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("User status check failed:", error);
        handleLogout();
      }
    } else {
      handleLogout();
    }
  };

  // useEffect(() => {
  //   const unsubAuth = onAuthStateChanged(auth, (currentUser) => {
  //     if (currentUser) {
  //       setUser(currentUser);
  //       Cookies.set('user', currentUser.uid);

  //       if (formId) {
  //         const formDocRef = doc(db, 'forms', formId);
  //         const unsubForm = onSnapshot(formDocRef, (doc) => {
  //           if (doc.exists()) {
  //             setFormData((prevData) => ({
  //               ...prevData,
  //               ...doc.data(),
  //               // Add any new fields with default values if they are not present in the doc
  //               showSponsorsW: doc.data().showSponsorsW || true,
  //               sponsorsW: doc.data().sponsorsW || [],
  //               sponsorWidthW: doc.data().sponsorWidthW || 65,
  //               sponsorsBottomW: doc.data().sponsorsBottomW || 85,
  //               sponsorsLeftW: doc.data().sponsorsLeftW || 0,
  //             }));
  //             setLoading(false);
  //           }
  //         });

  //         return () => unsubForm();
  //       }
  //     } else {
  //       setUser(null);
  //       Cookies.remove('user');
  //       navigate('/');
  //     }
  //   });

  //   return () => unsubAuth();
  // }, [formId]);

  useEffect(() => {
    if (user) {
      checkUserStatus(user);
    }
  }, [activeTab]);

  const updateGamePointsArrays = async (e) => {
    const newLength = e.target.value;
  
    // Create new arrays with the updated length
    const newGamesPointsA = [...formData.gamesPointsA];
    const newGamesPointsB = [...formData.gamesPointsB];
  
    if (newGamesPointsA.length < newLength) {
      // Add new elements filled with 0
      newGamesPointsA.push(...Array(newLength - newGamesPointsA.length).fill(0));
      newGamesPointsB.push(...Array(newLength - newGamesPointsB.length).fill(0));
    } else if (newGamesPointsA.length > newLength) {
      // Remove elements if new length is less than current length
      newGamesPointsA.length = newLength;
      newGamesPointsB.length = newLength;
    }
  
    // Ensure currentSet is within bounds of numberOfSets
    const newCurrentSet = Math.min(formData.currentSet, newLength);
  
    // Update the data
    const updatedData = {
      ...formData,
      gamesPointsA: newGamesPointsA,
      gamesPointsB: newGamesPointsB,
      numberOfSets: newLength,
      currentSet: newCurrentSet
    };
  
    // Set the updated data
    setFormData(updatedData);
  
    if (formId) {
      await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
    }
  };
  


  useEffect(() => {
  //if(!loading)  updateGamePointsArrays();
    console.log('num set' , formData)
  }, [formData.numberOfSets]);

  const toggleTimer = () => {
    setIsRunning(!isRunning);
    updateFirestore({ isRunning: !isRunning }); // Write updated data to Firestore
  };
  
  const increaseTime = (unit) => {
    setFormData((prevData) => {
      let updatedValue = { ...prevData };
      if (unit === 'sponsorWidth') updatedValue.sponsorWidth = prevData.sponsorWidth + 5;
      if (unit === 'sponsorsBottom') updatedValue.sponsorsBottom = prevData.sponsorsBottom + 5;
      if (unit === 'sponsorsLeft') updatedValue.sponsorsLeft = prevData.sponsorsLeft + 5;
     
      if (unit === 'sponsorWidthW') updatedValue.sponsorWidthW = prevData.sponsorWidthW + 10;
      if (unit === 'sponsorsBottomW') updatedValue.sponsorsBottomW = prevData.sponsorsBottomW + 10;
      if (unit === 'sponsorsLeftW') updatedValue.sponsorsLeftW = prevData.sponsorsLeftW + 10;
      
      if (unit === 'hours') updatedValue.hours = prevData.hours + 1;
      if (unit === 'minutes') updatedValue.minutes = prevData.minutes + 1;
      if (unit === 'seconds') setSeconds(seconds + 1);
      updateFirestore(updatedValue); // Write updated data to Firestore
      return updatedValue;
    });
  };
  
  const decreaseTime = (unit) => {
    setFormData((prevData) => {
      let updatedValue = { ...prevData };
      if (unit === 'sponsorWidth' && prevData.sponsorWidth > 0) updatedValue.sponsorWidth = prevData.sponsorWidth - 5;
      if (unit === 'sponsorsBottom' && prevData.sponsorsBottom > 0) updatedValue.sponsorsBottom = prevData.sponsorsBottom - 5;
      if (unit === 'sponsorsLeft') updatedValue.sponsorsLeft = prevData.sponsorsLeft - 5;
    
      if (unit === 'sponsorWidthW' && prevData.sponsorWidthW > 0) updatedValue.sponsorWidthW = prevData.sponsorWidthW - 10;
      if (unit === 'sponsorsBottomW' && prevData.sponsorsBottomW > 0) updatedValue.sponsorsBottomW = prevData.sponsorsBottomW - 10;
      if (unit === 'sponsorsLeftW') updatedValue.sponsorsLeftW = prevData.sponsorsLeftW - 10;
    
      if (unit === 'hours' && prevData.hours > 0) updatedValue.hours = prevData.hours - 1;
      if (unit === 'minutes' && prevData.minutes > 0) updatedValue.minutes = prevData.minutes - 1;
      if (unit === 'seconds' && seconds > 0) setSeconds(seconds - 1);
      updateFirestore(updatedValue); // Write updated data to Firestore
      return updatedValue;
    });
  };
  
  const handleGamesPointsChange = (player, operation) => {
    const index = formData.currentSet - 1;
    let newGamesPointsA = [...formData.gamesPointsA];
    let newGamesPointsB = [...formData.gamesPointsB];
  
    if (player === 'A') {
      if (operation === 'increase') {
        newGamesPointsA[index]++;
      } else {
        newGamesPointsA[index] = Math.max(0, newGamesPointsA[index] - 1);
      }
    } else if (player === 'B') {
      if (operation === 'increase') {
        newGamesPointsB[index]++;
      } else {
        newGamesPointsB[index] = Math.max(0, newGamesPointsB[index] - 1);
      }
    }
  
    const updatedData = {
      ...formData,
      gamesPointsA: newGamesPointsA,
      gamesPointsB: newGamesPointsB
    };
    setFormData(updatedData);
    updateFirestore(updatedData); // Write updated data to Firestore
  };
  
  const handleTieBreakChange = (player, operation) => {
    let newGamesPointsA = formData.tieBreakA;
    let newGamesPointsB = formData.tieBreakB;
  
    if (player === 'A') {
      if (operation === 'increase') {
        newGamesPointsA++;
      } else {
        newGamesPointsA = Math.max(0, newGamesPointsA - 1);
      }
    } else if (player === 'B') {
      if (operation === 'increase') {
        newGamesPointsB++;
      } else {
        newGamesPointsB = Math.max(0, newGamesPointsB - 1);
      }
    }
  
    const updatedData = {
      ...formData,
      tieBreakA: newGamesPointsA,
      tieBreakB: newGamesPointsB
    };
    setFormData(updatedData);
    updateFirestore(updatedData); // Write updated data to Firestore
  };
  
  

  const handleChange = async (e) => {
    const { name, value } = e.target;
    console.log(typeof value , value , 'vvvv')
    setFormData({ ...formData, [name]: name ===  value });

    if (formId) {
      await setDoc(doc(db, 'forms', formId), { ...formData, [name]: value }, { merge: true });
    }
  };

  const handlePointsChangeA = async (newPoints) => {
    setFormData((prevState) => ({ ...prevState, playerAPoints: newPoints }));

    if (formId) {
      await setDoc(doc(db, 'forms', formId), { ...formData, playerAPoints: newPoints }, { merge: true });
    }
  };

  const handlePointsChangeB = async (newPoints) => {
    setFormData((prevState) => ({ ...prevState, playerBPoints: newPoints }));

    if (formId) {
      await setDoc(doc(db, 'forms', formId), { ...formData, playerBPoints: newPoints }, { merge: true });
    }
  };
   
  const handleServeChange = async (serve) => {
    setFormData((prevState) => ({ ...prevState, serve: serve }));

    if (formId) {
      await setDoc(doc(db, 'forms', formId), { ...formData, serve: serve }, { merge: true });
    }
  };
   
const resetPoints = async () => {
  const updatedData = { ...formData, playerAPoints: 0, playerBPoints: 0 };
  setFormData(updatedData);

  if (formId) {
    await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
  }
};

const resetGamesPoints = async () => {
  const length = formData.numberOfSets;
  const resetArray = Array(length).fill(0);

  const updatedData = {
    ...formData,
    gamesPointsA: resetArray,
    gamesPointsB: resetArray,
    currentSet: 1
  };

  setFormData(updatedData);
  updateFirestore(updatedData); // Write updated data to Firestore
};

const handleResetPoints = () => {
  const index = formData.currentSet - 1;
  let newGamesPointsA = [...formData.gamesPointsA];
  let newGamesPointsB = [...formData.gamesPointsB];

  newGamesPointsA[index] = 0;
  newGamesPointsB[index] = 0;

  const updatedData = {
    ...formData,
    gamesPointsA: newGamesPointsA,
    gamesPointsB: newGamesPointsB
  };

  setFormData(updatedData);
  updateFirestore(updatedData); // Write updated data to Firestore
};

const resetTimer = () => {
  setSeconds(0);
  const updatedData = {
    ...formData,
    hours: 0,
    minutes: 0,
  };

  setFormData(updatedData);
  updateFirestore(updatedData); // Write updated data to Firestore
  setIsRunning(false);
  clearInterval(timerRef.current);
};


const handleToggleSwitch = async (property) => {
  const updatedData = { ...formData, [property]: !formData[property] };
  setFormData(updatedData);

  if (formId) {
    await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
  }
};


const handleIncrease = async () => {

    if (parseInt(formData.currentSet, 10) < parseInt(formData.numberOfSets, 10)) {
      const updatedCurrentSet = parseInt(formData.currentSet, 10) + 1;
      const updatedData = { ...formData, currentSet: updatedCurrentSet };

      // Update the local state
      setFormData(updatedData);

      // Write the updated data to Firestore
      if (formId) {
        await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
      }
    
  }
};


const handleDecrease = async () => {

    if (parseInt(formData.currentSet, 10) > 0) {
      const updatedCurrentSet = parseInt(formData.currentSet, 10) - 1;
      const updatedData = { ...formData, currentSet: updatedCurrentSet };
      
      // Update the local state
      setFormData(updatedData);

      // Write the updated data to Firestore
      if (formId) {
        await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
      }
    }
  
};


useEffect(() => {
  if (isRunning) {
    let secondsL=seconds;
    timerRef.current = setInterval(() => {
      setFormData((prevData) => {
        let { hours, minutes } = prevData;
        secondsL++;
        if (secondsL === 60) {
          secondsL = 0;
          minutes++;
        }
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
        const updatedData = { ...prevData, hours, minutes };
        setSeconds(secondsL)
        updateFirestore(updatedData); // Write updated data to Firestore
        return { ...prevData, hours, minutes };
      
        //return { ...prevData, hours, minutes };
      });
    }, 1000);
  } else {
    clearInterval(timerRef.current);
  }

  return () => clearInterval(timerRef.current);
}, [isRunning]);

const handleImageUpload = async (e) => {
  const imageFile = e.target.files[0];
  if (imageFile && imageFile.size <= maxFileSize) {
    setLoadingImg(true);
    const storageRef = ref(storage, `images/${imageFile.name}`);
    await uploadBytes(storageRef, imageFile);
    const imageUrl = await getDownloadURL(storageRef);
    setFormData({ ...formData, [e.target.name]: imageUrl });

    if (formId) {
      await setDoc(doc(db, 'forms', formId), { ...formData, [e.target.name]: imageUrl }, { merge: true });
    }
    setLoadingImg(false);
  } else {
    alert('File size exceeds 200KB limit. Please choose a smaller file or compress image before uploading');
  }
};

const handleAddSponsor = () => {
  if (formData.sponsors.length < 6) {
    setFormData(prevState => ({
      ...prevState,
      sponsors: [...prevState.sponsors, { name: '', image: null }]
    }));
  }
};

const handleAddSponsorW = () => {
  if (formData.sponsorsW.length < 6) {
    setFormData(prevState => ({
      ...prevState,
      sponsorsW: [...prevState.sponsorsW, { name: '', image: null }]
    }));
  }
};

const handleSponsorChange = (index, field, value) => {
  const updatedSponsors = [...formData.sponsors];
  updatedSponsors[index][field] = value;

  setFormData(prevState => ({
    ...prevState,
    sponsors: updatedSponsors
  }));

  updateFirestore({ sponsors: updatedSponsors });
};

const handleSponsorChangeW = (index, field, value) => {
  const updatedSponsorsW = [...formData.sponsorsW];
  updatedSponsorsW[index][field] = value;

  setFormData(prevState => ({
    ...prevState,
    sponsorsW: updatedSponsorsW
  }));

  updateFirestore({ sponsorsW: updatedSponsorsW });
};

const handleImageUploadS = async (index, file) => {
  if (file && file.size <= maxFileSize) {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    handleSponsorChange(index, 'image', imageUrl);

  } else {
    alert('File size exceeds 200KB limit. Please choose a smaller file or compress image before uploading');
  }
  // Upload image to Firebase Storage (this part depends on your Firebase setup)
  // const storageRef = firebase.storage().ref();
  // const fileRef = storageRef.child(file.name);
  // fileRef.put(file).then(() => {
  //   fileRef.getDownloadURL().then(url => {
  //     handleSponsorChange(index, 'image', url);
  //   });
  // });
};

const handleImageUploadSW = async (index, file) => {
  if (file && file.size <= maxFileSize) {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    handleSponsorChangeW(index, 'image', imageUrl);

  } else {
    alert('File size exceeds 200KB limit. Please choose a smaller file or compress image before uploading');
  }
};

const handleRemoveSponsor = (index) => {
  const updatedSponsors = [...formData.sponsors];
  updatedSponsors.splice(index, 1);

  setFormData(prevState => ({
    ...prevState,
    sponsors: updatedSponsors
  }));

  updateFirestore({ sponsors: updatedSponsors });
};

const handleRemoveSponsorW = (index) => {
  const updatedSponsorsW = [...formData.sponsorsW];
  updatedSponsorsW.splice(index, 1);

  setFormData(prevState => ({
    ...prevState,
    sponsorsW: updatedSponsorsW
  }));

  updateFirestore({ sponsorsW: updatedSponsorsW });
};

const updateFirestore = async (updatedData) => {
  if (formId) {
    await setDoc(doc(db, 'forms', formId), updatedData, { merge: true });
  }
};


  const renderTabContent = () => {

    switch (activeTab) {
      case 'Setup':
        return (
     
            <div className='tabs2'>
            <div className="form-group">
              <label htmlFor="playerA">Player A Name:</label>
              <input type="text" id="playerA" name="playerA" value={formData.playerA} onChange={handleChange} placeholder="Player A Name" />
            </div>
            <div className="form-group">
              <label htmlFor="playerB">Player B Name:</label>
              <input type="text" id="playerB" name="playerB" value={formData.playerB} onChange={handleChange} placeholder="Player B Name" />
            </div>
            <div className="form-group">
              <label htmlFor="numberOfSets">Number of Sets:</label>
              <select id="numberOfSets" name="numberOfSets" value={formData.numberOfSets} onChange={updateGamePointsArrays}>
                <option value={3}>3 Sets</option>
                <option value={5}>5 Sets</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="breakPointLabel">Break Point Label:</label>
              <input type="text" id="breakPointLabel" name="breakPointLabel" value={formData.breakPointLabel} onChange={handleChange} placeholder="Break Point Label" />
            </div> <br/>
            <div className="form-group ">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label htmlFor="playerA">Images Active</label>
      <div className={`switch ${formData.imagesOn ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('imagesOn')}>
      <div className={`switch-button ${formData.imagesOn ? 'switch-button-on' : 'switch-button-off'}`}>
      </div>
             </div> </div>
              </div></div>

            <div className="form-group form-group5">
              <label htmlFor="playerAImage">Player A Image</label>
              {/* <input type="text" id="playerAImage" name="playerAImage" value={formData.playerAImage} onChange={handleChange} placeholder="Player A Image URL" /> */}
              <input accept="image/*" type="file"  name="playerAImage" onChange={handleImageUpload} />
        {loadingImg?
          <CSpinner color="white" size="sm"  />
         : formData.playerAImage ? (
          <img src={formData.playerAImage} alt="Player A" />
        )
        :'' }
            </div>
            <div className="form-group form-group5">
              <label htmlFor="playerBImage">Player B Image</label>
              {/* <input type="text" id="playerBImage" name="playerBImage" value={formData.playerBImage} onChange={handleChange} placeholder="Player B Image URL" /> */}
              <input  accept="image/*" type="file" name="playerBImage" onChange={handleImageUpload} />
        {loadingImg?
          <CSpinner color="white" size="sm"  />
          : formData.playerBImage ? (
          <img src={formData.playerBImage} alt="Player B"  />
        ):''}
            </div>
     
          </div>
          
        );
        case 'Game':
          return (
       
              <div className='tabs2'>

              <div className="form-group form-group2">
              <label className='pointsLabel'>Points</label>
              
                <div className='pointsCollection' >
              
                <div className="app-field-radio">  
                <label htmlFor="playerA">{formData.playerA}</label>
                  <button className={`app-field-radio-button ${formData.playerAPoints === '0' ? 'selected' : ''}`} onClick={() => handlePointsChangeA('0')}>0</button>
                  <button className={`app-field-radio-button ${formData.playerAPoints === '15' ? 'selected' : ''}`} onClick={() => handlePointsChangeA('15')}>15</button>
                  <button className={`app-field-radio-button ${formData.playerAPoints === '30' ? 'selected' : ''}`} onClick={() => handlePointsChangeA('30')}>30</button>
                  <button className={`app-field-radio-button ${formData.playerAPoints === '40' ? 'selected' : ''}`} onClick={() => handlePointsChangeA('40')}>40</button>
                  <button className={`app-field-radio-button ${formData.playerAPoints === 'ADV' ? 'selected' : ''}`} onClick={() => handlePointsChangeA('ADV')}>ADV</button>
                </div>
                </div>
                <br />

                <div className='pointsCollection' >
                
                <div className="app-field-radio">
                <label htmlFor="playerA">{formData.playerB}</label>
                  <button className={`app-field-radio-button ${formData.playerBPoints === '0' ? 'selected' : ''}`} onClick={() => handlePointsChangeB('0')}>0</button>
                  <button className={`app-field-radio-button ${formData.playerBPoints === '15' ? 'selected' : ''}`} onClick={() => handlePointsChangeB('15')}>15</button>
                  <button className={`app-field-radio-button ${formData.playerBPoints === '30' ? 'selected' : ''}`} onClick={() => handlePointsChangeB('30')}>30</button>
                  <button className={`app-field-radio-button ${formData.playerBPoints === '40' ? 'selected' : ''}`} onClick={() => handlePointsChangeB('40')}>40</button>
                  <button className={`app-field-radio-button ${formData.playerBPoints === 'ADV' ? 'selected' : ''}`} onClick={() => handlePointsChangeB('ADV')}>ADV</button>
                </div>
                </div>
                <button className="app-button-reset" onClick={resetPoints} >Reset</button>

              </div> <br/>
              <div className="form-group form-group2">
              
                <div className='pointsCollection' >
              
                <div className="app-field-radio">  
                <label htmlFor="playerA">Serve</label>
                  <button className={`app-field-radio-button ${formData.serve === '0' ? 'selected' : ''}`} onClick={() => handleServeChange('0')}>None</button>
                  <button className={`app-field-radio-button ${formData.serve === 'A' ? 'selected' : ''}`} onClick={() => handleServeChange('A')}>Player A</button>
                  <button className={`app-field-radio-button ${formData.serve === 'B' ? 'selected' : ''}`} onClick={() => handleServeChange('B')}>Player B</button>
                </div>
                </div>             
              </div>

              <div className="form-group form-group2">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label htmlFor="playerA">Break Point</label>
    <div className={`switch ${formData.switchBreak ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('switchBreak')}>
      <div className={`switch-button ${formData.switchBreak ? 'switch-button-on' : 'switch-button-off'}`}>
      {/* {formData.switchBreak  ? 'On' : 'Off'} */}
      </div>
             </div> </div>
              </div></div>
          
            </div>
            
          );  
         
        case 'Match':
          return (
            <div className='tabs2'>
             <div className="form-group form-group2">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA">Current Set</label>
                  <div className="numeric-input-container">
      <button className="button" onClick={()=> handleDecrease()}>-</button>
      <input
        type="text"
        name='currentSet'
        value={formData.currentSet}
        max={parseInt(formData.numberOfSets, 10)}
        onChange={handleChange}
        pattern="\d*"
        className="numeric-input"
      />
      <button className="button" onClick={()=> handleIncrease()}>+</button>
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection pointsCollection2 ' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA">{formData.playerA}</label>
            {formData.numberOfSets &&  [...Array(parseInt(formData.numberOfSets, 10) )].map((_, i) => (
          <span key={i} className={formData.currentSet === i + 1 ? 'highlight' : ''}>
            {formData.gamesPointsA[i]}
          </span>
        ))}
             </div></div>
             </div>
             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection pointsCollection2 ' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA">{formData.playerB}</label>
             {[...Array(parseInt(formData.numberOfSets, 10) )].map((_, i) => (
          <span key={i} className={formData.currentSet === i + 1 ? 'highlight' : ''}>
            {formData.gamesPointsB[i]}
          </span>
        ))}
             </div></div>
             </div>

             <div className="form-group form-group2">
             <div className='pointsCollection pointsCollection2 ' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA"></label>
             <button className="app-button-reset" onClick={resetGamesPoints} >Reset</button>
            
             </div></div>
             </div>
   <br />
             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >{formData.playerA}</label>
                  <div className="numeric-input-container">
      <button className="button" onClick={() => handleGamesPointsChange('A', 'decrease')}>-</button>
      <span className='pointsSpan' >{formData.gamesPointsA[formData.currentSet-1]}</span>
      <button className="button" onClick={() => handleGamesPointsChange('A', 'increase')}>+</button>
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >{formData.playerB}</label>
                  <div className="numeric-input-container">
      <button className="button" onClick={() => handleGamesPointsChange('B', 'decrease')}>-</button>
      <span className='pointsSpan' >{formData.gamesPointsB[formData.currentSet-1]}</span>
      <button className="button" onClick={() => handleGamesPointsChange('B', 'increase')}>+</button>
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 ">
             <div className='pointsCollection pointsCollection2 ' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA"></label>
             <button className="app-button-reset" onClick={handleResetPoints} >Reset</button>
            
             </div></div>
             </div>
             
            </div>
          )

        case 'Tie-Break' : 
           return (
            <div className='tabs2'>

              <div className="form-group form-group2">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label >Tie Break</label>
      <div className={`switch ${formData.tieBreak ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('tieBreak')}>
      <div className={`switch-button ${formData.tieBreak ? 'switch-button-on' : 'switch-button-off'}`}>
      </div>
             </div> </div>
              </div></div>

              <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >{formData.playerA}</label>
                  <div className="numeric-input-container">
      <button className="button" onClick={() => handleTieBreakChange('A', 'decrease')}>-</button>
      <span className='pointsSpan' >{formData.tieBreakA}</span>
      <button className="button" onClick={() => handleTieBreakChange('A', 'increase')}>+</button>
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >{formData.playerB}</label>
                  <div className="numeric-input-container">
      <button className="button" onClick={() => handleTieBreakChange('B', 'decrease')}>-</button>
      <span className='pointsSpan' >{formData.tieBreakB}</span>
      <button className="button" onClick={() => handleTieBreakChange('B', 'increase')}>+</button>
                 </div>
             </div>
              </div>
             </div>

          </div>
              
           ) 
     
        case 'Match-Time' : 
        return (
          <div className='tabs2'>
            <div className="form-group form-group2 ">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label htmlFor="playerA">Match Time</label>
      <div className={`switch ${formData.timerOn ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('timerOn')}>
      <div className={`switch-button ${formData.timerOn ? 'switch-button-on' : 'switch-button-off'}`}>
      </div>
             </div> </div>
              </div></div>


              <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Hours</label>
                  <div className="numeric-input-container">
                  <button className="button" onClick={() => increaseTime('hours')}>+ </button>
                 <span className='pointsSpan' >{formData.hours}</span>   
                <button className="button timeButtons" onClick={() => decreaseTime('hours')}>-</button>
            
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Minutes</label>
                  <div className="numeric-input-container">
                <button className="button" onClick={() => increaseTime('minutes')}>+</button>
                <span className='pointsSpan' >{formData.minutes}</span>
               <button className="button" onClick={() => decreaseTime('minutes')}>-</button>
                 </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2 form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Seconds</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('seconds')}>+</button>
          <span className='pointsSpan' >{seconds}</span>
          <button className="button" onClick={() => decreaseTime('seconds')}>-</button>
        </div>
             </div>
              </div>
             </div>

             <div className="form-group form-group2">
             <div className='pointsCollection pointsCollection2 ' > 
             <div className="app-field-radio"> 
             <label htmlFor="playerA"></label>
             <button className="app-button-reset" onClick={toggleTimer} >{isRunning ? 'Pause' : 'Play'}</button>
             <button className="app-button-reset" onClick={resetTimer} >Reset</button>            
             </div></div>
             </div>

          </div>
        )
     
        case 'Sponsors' :       
        return (
          <div className='tabs2'>
                     { formData.sponsors && formData.sponsors.length>0 && <>
                  <div className="form-group ">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label>Sponsors Active</label>
      <div className={`switch ${formData.showSponsors ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('showSponsors')}>
      <div className={`switch-button ${formData.showSponsors ? 'switch-button-on' : 'switch-button-off'}`}>
      </div>
             </div> </div>
              </div></div>
              <br/>
              <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Logo Width</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorWidth')}>+</button>
          <span className='pointsSpan' >{formData.sponsorWidth}</span>
          <button className="button" onClick={() => decreaseTime('sponsorWidth')}>-</button>
        </div>
             </div>
              </div>
             </div>

             <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Bottom Spacing</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorsBottom')}>+</button>
          <span className='pointsSpan' >{formData.sponsorsBottom}</span>
          <button className="button" onClick={() => decreaseTime('sponsorsBottom')}>-</button>
        </div>
             </div>
              </div>
             </div>

             <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Left Spacing</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorsLeft')}>+</button>
          <span className='pointsSpan' >{formData.sponsorsLeft}</span>
          <button className="button" onClick={() => decreaseTime('sponsorsLeft')}>-</button>
        </div>
             </div>
              </div>
             </div>
             <br/>
              </>
              }

      <div>
      {formData.sponsors.map((sponsor, index) => (
        <div key={index} className="sponsor-input">
          <div className="form-group form-group5">
            <label htmlFor="playerA">Sponsor Name</label>
          <input
            type="text"
            value={sponsor.name}
            onChange={(e) => handleSponsorChange(index, 'name', e.target.value)}
           // placeholder="Sponsor Name"
          />   
           </div>
           <div className="form-group form-group5">
           <label >Sponsor Logo</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUploadS(index, e.target.files[0])}
          />
         
        
            <div className="thumbnail">
            {sponsor.image && ( 
              <> <img src={sponsor.image} alt={`Sponsor ${index}`} />
          
           </>)} 
           </div>
           </div>
           <button onClick={() => handleRemoveSponsor(index)} className="c-btn c-btn-outline-primary deleteButton">
                <CIcon size={'5xl'} name={'cilTrash'} />Remove</button>
        </div>
      ))}
      {formData.sponsors.length < 6 && (
        <button onClick={handleAddSponsor} className="app-button-reset" >Add Sponsor</button>
      )}
      </div>
          </div>

        )

        case 'SponsorsW' :       
        return (
  
           <div className='tabs2'>
                     { formData.sponsorsW && formData.sponsorsW.length>0 && 
                     <>
                  <div className="form-group ">
              <div className='pointsCollection' >
              <div className="app-field-radio">  
                <label>Sponsors Active</label>
      <div className={`switch ${formData.showSponsorsW ? 'switch-on' : 'switch-off'}`} onClick={()=> handleToggleSwitch('showSponsorsW')}>
      <div className={`switch-button ${formData.showSponsorsW ? 'switch-button-on' : 'switch-button-off'}`}>
      </div>
             </div> </div>
              </div></div>
              <br/>
              <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Logo Width</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorWidthW')}>+</button>
          <span className='pointsSpan' >{formData.sponsorWidthW}</span>
          <button className="button" onClick={() => decreaseTime('sponsorWidthW')}>-</button>
        </div>
             </div>
              </div>
             </div>

             <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Bottom Spacing</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorsBottomW')}>+</button>
          <span className='pointsSpan' >{formData.sponsorsBottomW}</span>
          <button className="button" onClick={() => decreaseTime('sponsorsBottomW')}>-</button>
        </div>
             </div>
              </div>
             </div>

             <div className="form-group  form-group3">
             <div className='pointsCollection' > 
             <div className="app-field-radio"> 
             <label >Sponsor Left Spacing</label>
                  <div className="numeric-input-container">
          <button className="button" onClick={() => increaseTime('sponsorsLeftW')}>+</button>
          <span className='pointsSpan' >{formData.sponsorsLeftW}</span>
          <button className="button" onClick={() => decreaseTime('sponsorsLeftW')}>-</button>
        </div>
             </div>
              </div>
             </div>
             <br/>
              </>
              }

      <div>
      {formData.sponsorsW && formData.sponsorsW.map((sponsor, index) => (
        <div key={index} className="sponsor-input">
          <div className="form-group form-group5">
            <label htmlFor="playerA">Sponsor Name</label>
          <input
            type="text"
            value={sponsor.name}
            onChange={(e) => handleSponsorChangeW(index, 'name', e.target.value)}
           // placeholder="Sponsor Name"
          />   
           </div>
           <div className="form-group form-group5">
           <label >Sponsor Logo</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUploadSW(index, e.target.files[0])}
          />
         
        
            <div className="thumbnail">
            {sponsor.image && ( 
              <> <img src={sponsor.image} alt={`Sponsor ${index}`} />
          
           </>)} 
           </div>
           </div>
           <button onClick={() => handleRemoveSponsorW(index)} className="c-btn c-btn-outline-primary deleteButton">
                <CIcon size={'5xl'} name={'cilTrash'} />Remove</button>
        </div>
      ))}
      {formData.sponsorsW && formData.sponsorsW.length < 6 && (
        <button onClick={handleAddSponsorW} className="app-button-reset" >Add Sponsor</button>
      )}
      </div>
          </div> 

        )

      default:
        return null;
    }
  };

  // const copyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text).then(() => {
  //     alert('Copied to clipboard!');
  //   }).catch((error) => {
  //     console.error('Failed to copy:', error);
  //   });
  // };

  const copyToClipboard = (text) => {
    //if (navigator.clipboard && navigator.clipboard.writeText) 
    if(0){
      navigator.clipboard.writeText(text).then(() => {
        alert('Copied to clipboard!');
      }).catch((error) => {
        console.error('Failed to copy:', error);
        fallbackCopyToClipboard(text);
      });
    } else {
      fallbackCopyToClipboard(text);
    }
  };
  
  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
  
    // Avoid scrolling to the bottom of the page
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'Copied to clipboard!' : 'Failed to copy';
      alert(msg);
    } catch (error) {
      console.error('Fallback: Failed to copy', error);
    }
  
    document.body.removeChild(textArea);
  };
  

  if (loading) return <div>Loading...</div>;
 else  return (
    <>
    
    {formData && 
    <div>
 

    <div className="edit-form-container editPage">
 
 <CRow>
  <CCol md='6' className='editLeft'>
  <CRow>


  <CCol md='3'>
   <ul className="nav nav-tabs">
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Setup' ? 'active' : ''}`} onClick={() => setActiveTab('Setup')}>Setup</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Game' ? 'active' : ''}`} onClick={() => setActiveTab('Game')}>Game</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Match' ? 'active' : ''}`} onClick={() => setActiveTab('Match')}>Match</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Tie-Break' ? 'active' : ''}`} onClick={() => setActiveTab('Tie-Break')}>Tie-Break</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Match-Time' ? 'active' : ''}`} onClick={() => setActiveTab('Match-Time')}>Match Time</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'Sponsors' ? 'active' : ''}`} onClick={() => setActiveTab('Sponsors')}>Sponsors</button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 'SponsorsW' ? 'active' : ''}`} onClick={() => setActiveTab('SponsorsW')}>Logo on stadium screen</button>
        </li>
      </ul>  
      </CCol>
  
      <CCol md='9'>
 
      <div className="tab-content mt-3">
        <div className={`tab-pane fade show active`}>
          {renderTabContent()}
        </div>
        
      </div>  
         </CCol>
        </CRow>   
  </CCol>

  <CCol md='6'>

    
    <Preview formData={formData} />
      <div className='linkCopies'>
      <div className='linkCopies1'>
      <span className='linkCopiesLabel'>Live Link:</span>  
      <div className='linkCopiesContent'> 
     <button className="c-btn c-btn-outline-primary copyButton" 
    onClick={() => copyToClipboard(`http://sportlive.tn/display/${formData.uniqueKey}`)}>
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ui-svg-inline"><path d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"></path></svg>
     Copy Link</button>
      {qrCodeUrl && (
        <> <img src={qrCodeUrl} alt="QR Code" style={{ width: '100px', height: '100px' }} />
       
      </> )}
  
       </div> </div> 
       <div className='linkCopies1'>
      <span className='linkCopiesLabel'>Live Link Wide Screen:</span>  
      <div className='linkCopiesContent'> 
     <button className="c-btn c-btn-outline-primary copyButton" 
    onClick={() => copyToClipboard(`http://sportlive.tn/display/wide/${formData.uniqueKey}`)}>
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="ui-svg-inline"><path d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"></path></svg>
     Copy Link</button>
      {qrCodeUrlW && (
        <> <img src={qrCodeUrlW} alt="QR Code" style={{ width: '100px', height: '100px' }} />
       
      </> )}
  
       </div> </div> 
     </div>
</CCol>
 </CRow>

  

    </div>
    </div>}
    </>
  );
};

export default EditForm;
