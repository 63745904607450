import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import './scss/style.scss';
import "./assets/css/style.css";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Display from './components/Display';
import DisplayWide from './components/DisplayWide';
import EditForm from './components/EditForm';
import Cookies from 'js-cookie';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';

const AppContent = ({ isLoggedIn, handleLogout, setIsLoggedIn }) => {
  const location = useLocation();

  const shouldShowHeaderAndFooter = () => {
    return !location.pathname.startsWith('/display/');
  };

  return (
    <div className="app">
      {shouldShowHeaderAndFooter() && (
        <header className="app-header">
          <nav>
            <Link to="/">
              <img src='/assets/images/logo-sport-live.png' alt="Logo" />
            </Link>
            <div className='ourPartners'>
              Our Partners <br/>
              <img src='/assets/images/ADN-Broadcast.png' alt="logo-adn" /> &nbsp; &nbsp;
              <img src='/assets/images/grp-logo-.png' alt="grp-logo" />
            </div>
            {isLoggedIn && (
              <>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
              </>
            )}
          </nav>
        </header>
      )}
      <main className="app-content">
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={() => setIsLoggedIn(true)} />} />
          <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/edit-form/:formId" element={isLoggedIn ? <EditForm /> : <Navigate to="/" />} />
          <Route path="/display/:uniqueKey" element={<Display />} />
          <Route path="/display/Wide/:uniqueKey" element={<DisplayWide />} />
        </Routes>
      </main>
      {shouldShowHeaderAndFooter() && (
        <footer className="app-footer">
          <p>© 2024 Sport Live. All rights reserved. Produced by Dozane Studio</p>
        </footer>
      )}
    </div>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const user = Cookies.get('user');
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      setIsLoggedIn(false);
      Cookies.remove('user');
    });
  };

  if (isLoggedIn === null) {
    // Optionally, you can show a loading spinner or some placeholder content while determining the login status
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <AppContent isLoggedIn={isLoggedIn} handleLogout={handleLogout} setIsLoggedIn={setIsLoggedIn} />
    </Router>
  );
};

export default App;
